var CONFUSEDCORE = CONFUSEDCORE || {};

//// THIS IS TO INITIALISE COOKIE POP UP & TRIGGER MI EVENTS IN STANDALONE COOKIE POP UP SCRIPT
//// TO MAINLY BE USED ON STANDALONE PIECES TO BE HOSETED IN CMS

// This function runs when the page is loaded
document.addEventListener('DOMContentLoaded', function () {
	const pageUrl = window.location.href;

	const cookiePolicyWidget = window.Confused.CookiePolicyWidget;
	cookiePolicyWidget.run();

	// TRIGGER NEW SESSION CALL IN MI - WAIT FOR CALLBACK FROM SESSION CALL THEN TRIGGER COOKIE POPUP SHOWN EVENT
	const miListener = CONFUSEDCORE.MI.MiListener;
	miListener.postPageView('LogPageView', { page: pageUrl, referer: document.referrer }, function () {
		if (cookiePolicyWidget.wasCookiePopupShown()) {
			miListener.postEvent('CookiePopupShown');
		}
	});
});
